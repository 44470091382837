// src/pages/AdminPage.js
import React, { useState } from 'react';
import BuildingDetails from '../components/Admin/BuildingDetails';
import ParkingSpaceTable from '../components/Admin/ParkingSpaceTable';
import ParkingSpaceForm from '../components/Admin/ParkingSpaceForm';
import Header from '../components/Common/Header';
import withAuth from '../components/Auth/withAuth';

const AdminPage = () => {
    const [buildingId, setBuildingId] = useState('');
    const [floorId, setFloorId] = useState('');
    const [selectedSpace, setSelectedSpace] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const handleSave = () => {
        setIsEditing(false);
        setSelectedSpace(null);
        // 重新載入資料
    };

    const handleCancel = () => {
        setIsEditing(false);
        setSelectedSpace(null);
    };

    return (
        <div className="flex flex-col h-screen">
            <Header />
            <div className="flex flex-col md:flex-row">
                <BuildingDetails setBuildingId={setBuildingId} setFloorId={setFloorId} />
                <div className="w-full p-4">
                    {isEditing ? (
                        <ParkingSpaceForm
                            space={selectedSpace}
                            buildingId={buildingId}
                            floorId={floorId}
                            onSave={handleSave}
                            onCancel={handleCancel}
                            isEdit={selectedSpace !== null}
                        />
                    ) : (
                        <ParkingSpaceTable
                            buildingId={buildingId}
                            floorId={floorId}
                            onEdit={space => { setSelectedSpace(space); setIsEditing(true); }}
                            onDelete={spaceId => {/* 刪除邏輯 */}}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default withAuth(AdminPage);


// // src/pages/AdminPage.js
// import React from 'react';
// // import { Admin, Resource, ListGuesser, EditGuesser } from 'react-admin';
// import { Admin, Resource } from 'react-admin';
// import fakeRestProvider from 'ra-data-fakerest';
// import { Route, Routes } from 'react-router-dom';

// import UserList from '../components/Admin/UserList';
// import UserEdit from '../components/Admin/UserEdit';
// import UserCreate from '../components/Admin/UserCreate';
// import AdminLayout from '../components/Admin/AdminLayout';
// import NotFound from '../components/Admin/NotFound';

// // 使用假數據
// // const dataProvider = fakeRestProvider({
// //   users: [
// //     { id: 1, name: 'John Doe', email: 'john.doe@example.com' },
// //     { id: 2, name: 'Jane Smith', email: 'jane.smith@example.com' },
// //   ],
// // });
// const dataProvider = fakeRestProvider({
//     "users": [
//         {
//             "id": 1,
//             "name": "John Doe",
//             "email": "john.doe@example.com"
//         },
//         {
//             "id": 2,
//             "name": "Jane Smith",
//             "email": "jane.smith@example.com"
//         }
//     ]
// });

// const AdminPage = () => {
//   return (
//     <Routes>
//       <Route
//         path="/*"
//         element={
//           <Admin
//             dataProvider={dataProvider}
//             layout={AdminLayout}
//             catchAll={NotFound}
//             basename="/admin"
//           >
//             <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} />
//           </Admin>
//         }
//       />
//     </Routes>
//   );
// };

// export default AdminPage;
