// src/components/Building/ParkingLot.js
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Stage, Layer, Rect, Text, Circle, Line } from 'react-konva';
import config from '../../config';

const ParkingLot = ({ buildingId, floorId }) => {
  const [spaces, setSpaces] = useState([]);
  const [floorInfo, setFloorInfo] = useState(null);
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [objects, setObjects] = useState([]);
  const stageRef = useRef(null);

  useEffect(() => {
    axios.get(`${config.apiBaseUrl}/building/${buildingId}/parking_floor/${floorId}`)
      .then(response => {
        setSpaces(response.data.floor_data || []);
        setFloorInfo(response.data);
        const stage = stageRef.current;
        stage.scale({ x: 0.9, y: 0.9 });
        stage.batchDraw();
      })
      .catch(error => {
        console.error('There was an error fetching the parking spaces!', error);
      });

      // 根據大樓ID獲取共同物件資訊
      axios.get(`${config.apiBaseUrl}/building/${buildingId}/objects`)
      .then(response => {
        setObjects(response.data.objects || []);
      })
      .catch(error => {
        console.error('There was an error fetching the objects!', error);
      });

  }, [buildingId, floorId]);

  // 畫布基於視窗大小之比例
  const originCanvasWidth = window.innerWidth * 0.85;
  const originCanvasHeight = window.innerHeight * 0.83;
  let canvasWidth = window.innerWidth * 0.85;
  let canvasHeight = window.innerHeight * 0.83;

  // 縮放處理
  const handleWheel = (e) => {
    e.evt.preventDefault();
    const stage = stageRef.current;
    const oldScale = stage.scaleX();
    const pointer = stage.getPointerPosition();

    const mousePointTo = {
      x: (pointer.x - stage.x()) / oldScale,
      y: (pointer.y - stage.y()) / oldScale,
    };

    const newScale = e.evt.deltaY > 0 ? Math.min(oldScale / 1.1, 8.5) : Math.max(oldScale * 1.1, 0.7);
    stage.scale({ x: newScale, y: newScale });
    const newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale,
    };
    stage.position(newPos);
    stage.batchDraw();
  };

  // 點擊車位
  const handleSpaceClick = (space) => {
    setSelectedSpace(space);
  };
  const handleSpaceTap = (space) => {
    setSelectedSpace(space);
  };

  // 判斷點擊畫布空白處
  const handleStageClick = (e) => {
    if (e.target === e.target.getStage() || e.target.attrs.fill === 'white') {
      setSelectedSpace(null);
    }
  };
  const handleStageTap = (e) => {
    if (e.target === e.target.getStage() || e.target.attrs.fill === 'white') {
      setSelectedSpace(null);
    }
  };

  // 關閉車位資訊視窗
  const closeInfoWindow = () => {
    setSelectedSpace(null);
  };

  // [重置]按鈕
  const resetZoom = () => {
    const stage = stageRef.current;
    stage.scale({ x: 0.9, y: 0.9 });
    stage.position({ x: originCanvasWidth * 0.2, y: originCanvasHeight * 0.03 });
    stage.batchDraw();
  };

  // [+]按鈕 (zoomIn)
  const zoomIn = () => {
    const stage = stageRef.current;
    const oldScale = stage.scaleX();
    const center = {
      x: stage.width() / 2,
      y: stage.height() / 2
    };
    const newScale = Math.min(oldScale * 1.3, 8.5);
    const newPos = {
      x: center.x - (center.x - stage.x()) * (newScale / oldScale),
      y: center.y - (center.y - stage.y()) * (newScale / oldScale)
    };
    stage.scale({ x: newScale, y: newScale });
    stage.position(newPos);
    stage.batchDraw();
  };

  // [-]按鈕 (zoomOut)
  const zoomOut = () => {
    const stage = stageRef.current;
    const oldScale = stage.scaleX();
    const center = {
      x: stage.width() / 2,
      y: stage.height() / 2
    };
    const newScale = Math.max(oldScale / 1.3, 0.7);
    const newPos = {
      x: center.x - (center.x - stage.x()) * (newScale / oldScale),
      y: center.y - (center.y - stage.y()) * (newScale / oldScale)
    };
    stage.scale({ x: newScale, y: newScale });
    stage.position(newPos);
    stage.batchDraw();
  };

  if (floorInfo) {
    const windowScale = window.innerWidth / window.innerHeight;
    const dpiScale = (floorInfo.floor_dpi[0] / floorInfo.floor_dpi[1]);

    if (windowScale > dpiScale) {
      canvasWidth = canvasHeight * dpiScale;
    } else {
      canvasHeight = canvasWidth / dpiScale;
    }
  }

  // 分割文字並調整大小以適應物件
  const splitText = (text, maxCharsPerLine) => {
    const lines = [];
    let line = '';
    for (let i = 0; i < text.length; i++) {
      if (line.length < maxCharsPerLine) {
        line += text[i];
      } else {
        lines.push(line);
        line = text[i];
      }
    }
    if (line) lines.push(line);
    return lines.join('\n');
  };

  return (
    <div className="relative bg-blue-50">
      {floorInfo && floorInfo.floor_coordinates && spaces.length > 0 && (
        <div className="relative">
          <Stage
            x={originCanvasWidth * 0.2}
            y={originCanvasHeight * 0.03}
            width={originCanvasWidth}
            height={originCanvasHeight}
            ref={stageRef}
            onWheel={handleWheel}
            onClick={handleStageClick}
            onTap={handleStageTap}
            draggable
          >
            <Layer>
              <Line
                points={floorInfo.floor_coordinates.reduce((acc, point) => [...acc, point.x * canvasWidth, point.y * canvasHeight], [])}
                closed
                stroke="black"
                fill="white"
              />
              {spaces.map(space => (
                space.coordinates && (
                <React.Fragment key={space.id}>
                  <Rect
                    x={space.coordinates[0].x * canvasWidth}
                    y={space.coordinates[0].y * canvasHeight}
                    width={(space.coordinates[1].x - space.coordinates[0].x) * canvasWidth}
                    height={(space.coordinates[3].y - space.coordinates[0].y) * canvasHeight}
                    fill={space.status === 'buyable' ? '#E6C35C' : space.status === 'rentable' ? '#00aac8' : space.status === 'buyable_rentable' ? '#E6C3C3' : '#cccccc'}
                    stroke="black"
                    strokeWidth={1}
                    onClick={() => handleSpaceClick(space)}
                    onTap={() => handleSpaceTap(space)}
                    hitStrokeWidth={1} // 調整觸控面積，過大會造成面積覆蓋，斟酌設置
                  />
                  <Circle
                    x={(space.coordinates[0].x + (space.coordinates[1].x - space.coordinates[0].x) / 2) * canvasWidth}
                    y={(space.coordinates[0].y + (space.coordinates[3].y - space.coordinates[0].y) / 2) * canvasHeight}
                    radius={Math.min((space.coordinates[1].x - space.coordinates[0].x) * canvasWidth, (space.coordinates[3].y - space.coordinates[0].y) * canvasHeight) / 2}
                    fill="transparent"
                    stroke="black"
                    strokeWidth={1}
                    onClick={() => handleSpaceClick(space)}
                    onTap={() => handleSpaceTap(space)}
                  />
                  <Text
                    text={space.number}
                    fontSize={(Math.min((space.coordinates[1].x - space.coordinates[0].x) * canvasWidth, (space.coordinates[3].y - space.coordinates[0].y) * canvasHeight) / 2) * 0.75}
                    x={(space.coordinates[0].x + (space.coordinates[1].x - space.coordinates[0].x) / 2) * canvasWidth - ((Math.min((space.coordinates[1].x - space.coordinates[0].x) * canvasWidth, (space.coordinates[3].y - space.coordinates[0].y) * canvasHeight) / 2) * 0.75) / 2 * space.number.length / 2 / 0.9}
                    y={(space.coordinates[0].y + (space.coordinates[3].y - space.coordinates[0].y) / 2) * canvasHeight - ((Math.min((space.coordinates[1].x - space.coordinates[0].x) * canvasWidth, (space.coordinates[3].y - space.coordinates[0].y) * canvasHeight) / 2) * 0.75) / 2 * 0.9}
                    fill="black"
                    align="center"
                    verticalAlign="middle"
                    onClick={() => handleSpaceClick(space)}
                    onTap={() => handleSpaceTap(space)}
                  />
                </React.Fragment>
                )
              ))}
              {/* 繪製大樓的共同物件 */}
              {objects.map(object => {
                const text = object.attribute_name === "elevator_entrance" ? object.name + "棟電梯" :
                  (object.attribute_name === "vehicle_entrance" && object.name === "vehicle_entrance") ? "車輛入口" :
                  (object.attribute_name === "vehicle_entrance" && object.name === "vehicle_exit") ? "車輛出口" :
                  object.name;
                const maxCharsPerLine = Math.ceil(text.length / 2);
                const lines = splitText(text, maxCharsPerLine);
                return (
                  <React.Fragment key={object.id}>
                    <Rect
                      x={object.coordinates[0].x * canvasWidth}
                      y={object.coordinates[0].y * canvasHeight}
                      width={(object.coordinates[1].x - object.coordinates[0].x) * canvasWidth}
                      height={(object.coordinates[3].y - object.coordinates[0].y) * canvasHeight}
                      fill={object.attribute_name === "elevator_entrance" ? "deepblue" : "green"}
                      stroke="black"
                      strokeWidth={1}
                    />
                    <Text
                      x={object.coordinates[0].x * canvasWidth + ((object.coordinates[1].x - object.coordinates[0].x) * canvasWidth) / 2}
                      y={object.coordinates[0].y * canvasHeight + ((object.coordinates[3].y - object.coordinates[0].y) * canvasHeight) / 2}
                      width={(object.coordinates[1].x - object.coordinates[0].x) * canvasWidth}
                      height={(object.coordinates[3].y - object.coordinates[0].y) * canvasHeight}
                      align="center"
                      verticalAlign="middle"
                      text={lines}
                      fill="white"
                      fontStyle='bold'
                      fontSize={Math.min(
                        (object.coordinates[1].x - object.coordinates[0].x) * canvasWidth,
                        (object.coordinates[3].y - object.coordinates[0].y) * canvasHeight
                      ) * 0.75 / maxCharsPerLine}
                      offsetX={((object.coordinates[1].x - object.coordinates[0].x) * canvasWidth) * 0.5}
                      offsetY={((object.coordinates[3].y - object.coordinates[0].y) * canvasHeight) * 0.5}
                    />
                  </React.Fragment>
                )
              })}
            </Layer>
          </Stage>
          <div className="absolute right-3 top-1/2 transform -translate-y-1/2 flex flex-col items-center space-y-4">
            <button
              className="w-12 p-2 bg-blue-500 text-white rounded"
              onClick={zoomIn}
            >
              + 
            </button>

            <button
              className="p-2 bg-blue-500 text-white rounded"
              onClick={resetZoom}
            >
              重置
            </button>

            <button
              className="w-12 p-2 bg-blue-500 text-white rounded"
              onClick={zoomOut}
            >
              -
            </button>
          </div>
        </div>
      )}
      {selectedSpace && (
        <div className="fixed md:absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 p-5 bg-white border border-gray-300 shadow-lg z-50">
          <div className="flex justify-between items-center mb-5">
            <h2 className="m-0">
              <span className="inline-block w-5 h-5 mr-2" style={{ backgroundColor: selectedSpace.status === 'buyable' ? '#E6C35C' : selectedSpace.status === 'rentable' ? '#00aac8' : selectedSpace.status === 'buyable_rentable' ? '#E6C3C3' : '#cccccc' }}></span>
              車位資訊
            </h2>
            <button onClick={closeInfoWindow} onTap={closeInfoWindow} className="text-2xl">&times;</button>
          </div>
          {selectedSpace.status === 'unusable' ? (
            <p>該車位目前無法使用</p>
          ) : (
            <>
              <p>車位編號：{selectedSpace.number}</p>
              <p>填表日期：{selectedSpace.date_added}</p>
              <p>車輛類型：{selectedSpace.vehicle_type === 'car' ? '汽車' : selectedSpace.vehicle_type === 'motorcycle' ? '機車' : selectedSpace.vehicle_type === 'car_motorcycle' ? '汽機車' : selectedSpace.vehicle_type}</p>
              {selectedSpace.status === 'buyable' && (
                <p>車位售價：{selectedSpace.price}元</p>
              )}
              {selectedSpace.status === 'rentable' && (
                <p>車位租金：{selectedSpace.monthly_rent && `${selectedSpace.monthly_rent}元/月 `}{selectedSpace.yearly_rent && `${selectedSpace.yearly_rent}元/年`}</p>
              )}
              {selectedSpace.status === 'buyable_rentable' && (
                <>
                  <p>車位售價：{selectedSpace.price}元</p>
                  <p>車位租金：{selectedSpace.monthly_rent && `${selectedSpace.monthly_rent}元/月 `}{selectedSpace.yearly_rent && `${selectedSpace.yearly_rent}元/年`}</p>
                </>
              )}
              <p>聯絡人：{selectedSpace.contact_name}</p>
              <p>聯絡電話：{selectedSpace.contact_phone}</p>
              <p>備註：{selectedSpace.note}</p>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ParkingLot;
