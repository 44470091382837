// 對應版本: 1.7
// src/context/AuthContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import userCredentials from '../config/userCredentials';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated');
    const expiryTime = localStorage.getItem('expiryTime');
    const username = localStorage.getItem('username');

    if (authStatus === 'true' && expiryTime) {
      const currentTime = new Date().getTime();
      if (currentTime < parseInt(expiryTime, 10)) {
        const user = userCredentials.find(user => user.username === username);
        if (user) {
          setIsAuthenticated(true);
          setCurrentUser(user);
        } else {
          setIsAuthenticated(false);
        }
      } else {
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('expiryTime');
        localStorage.removeItem('username');
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
    }
    setIsAuthenticating(false);
  }, []);

  const login = (username) => {
    const currentTime = new Date().getTime();
    const expiryTime = currentTime + 60 * 60 * 1000; // 設定1小時後過期
    const user = userCredentials.find(user => user.username === username);
    if (user) {
      setIsAuthenticated(true);
      setCurrentUser(user);
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('expiryTime', expiryTime.toString());
      localStorage.setItem('username', username);
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    setCurrentUser(null);
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('expiryTime');
    localStorage.removeItem('username');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, isAuthenticating, currentUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const PrivateRoute = ({ children }) => {
  const { isAuthenticated, isAuthenticating } = useContext(AuthContext);
  if (isAuthenticating) return <div>Loading...</div>;
  return isAuthenticated ? children : <Navigate to="/login" />;
};
