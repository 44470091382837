// src/components/Common/Header.js
// 1.7
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

const Header = () => {
  const { isAuthenticated, logout, currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const goToHome = () => {
    navigate('/home');
  };

  const goToProfile = () => {
    navigate('/profile');
  };

  const goToAdmin = () => {
    navigate('/admin');
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <div className="flex justify-between items-center p-4 bg-blue-500 text-white">
        <h1 className="text-2xl">停車場佈告欄</h1>
        <div className="hidden md:flex">
          <button 
            onClick={goToHome} 
            className="mr-4 px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition">
            首頁
          </button>
          {!isAuthenticated ? (
            <button 
              onClick={() => navigate('/login')} 
              className="mr-4 px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition">
              登入
            </button>
          ) : (
            <>
              <button 
                onClick={goToProfile}
                className="mr-4 px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition"
                disabled={true}  
              >
                會員資料
              </button>
              <button 
                onClick={goToAdmin}
                className="mr-4 px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition"
                disabled={!(currentUser && ['user9', 'user10'].includes(currentUser.username))}
              >
                後台管理
              </button>
              <button 
                onClick={handleLogout} 
                className="mr-4 px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition">
                登出
              </button>
            </>
          )}
        </div>
        <div className="md:hidden relative">
          <button
            onClick={toggleDropdown}
            className="px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition"
          >
            選單
          </button>
          {isDropdownOpen && (
            <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-lg shadow-xl z-20">
              <button 
                onClick={goToHome} 
                className="block px-4 py-2 text-blue-500 hover:bg-blue-200 hover:text-white transition w-full text-left">
                首頁
              </button>
              {!isAuthenticated ? (
                <button 
                  onClick={() => navigate('/login')} 
                  className="block px-4 py-2 text-blue-500 hover:bg-blue-200 hover:text-white transition w-full text-left">
                  登入
                </button>
              ) : (
                <>
                  <button 
                    onClick={goToProfile}
                    className="block px-4 py-2 text-blue-500 hover:bg-blue-200 hover:text-white transition w-full text-left"
                    disabled={true}
                  >
                    會員資料
                  </button>
                  <button 
                    onClick={goToAdmin}
                    className="block px-4 py-2 text-blue-500 hover:bg-blue-200 hover:text-white transition w-full text-left"
                    disabled={!(currentUser && ['user9', 'user10'].includes(currentUser.username))}
                  >
                    後台管理
                  </button>
                  <button 
                    onClick={handleLogout} 
                    className="block px-4 py-2 text-blue-500 hover:bg-blue-200 hover:text-white transition w-full text-left">
                    登出
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {isMobile && (
        <div className="fixed bottom-0 left-0 w-full bg-yellow-400 text-black text-center py-2 z-50 text-pretty">
          <p>使用電腦版檢視，以獲得更佳瀏覽體驗</p>
          <p>Please use larger monitor for better experience.</p>
        </div>
      )}
    </div>
  );
};

export default Header;


// // 1.6 除了RWD設計，另外加入了提示橫幅，建議使用者使用電腦畫面瀏覽
// // src/components/Common/Header.js
// import React, { useContext, useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { AuthContext } from '../../context/AuthContext';

// const Header = () => {
//   const { isAuthenticated, logout } = useContext(AuthContext);
//   const navigate = useNavigate();
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

//   const handleLogout = () => {
//     logout();
//     navigate('/login');
//   };

//   const goToHome = () => {
//     navigate('/home');
//   };

//   const goToProfile = () => {
//     navigate('/profile');
//   };

//   const goToAdmin = () => {
//     navigate('/admin');
//   };

//   const toggleDropdown = () => {
//     setIsDropdownOpen(!isDropdownOpen);
//   };

//   // 監聽視窗大小變化
//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth < 768);
//     };

//     window.addEventListener('resize', handleResize);
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);

//   return (
//     <div>
//       <div className="flex justify-between items-center p-4 bg-blue-500 text-white">
//         <h1 className="text-2xl">停車場佈告欄</h1>
//         <div className="hidden md:flex">
//           <button 
//             onClick={goToHome} 
//             className="mr-4 px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition">
//             首頁
//           </button>
//           {!isAuthenticated ? (
//             <button 
//               onClick={() => navigate('/login')} 
//               className="mr-4 px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition">
//               登入
//             </button>
//           ) : (
//             <>
//               <button 
//                 onClick={goToProfile}
//                 className="mr-4 px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition"
//                 disabled={true}  
//               >
//                 會員資料
//               </button>
//               <button 
//                 onClick={goToAdmin}
//                 className="mr-4 px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition"
//                 // disabled={true}
//               >
//                 後台管理
//               </button>
//               <button 
//                 onClick={handleLogout} 
//                 className="mr-4 px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition">
//                 登出
//               </button>
//             </>
//           )}
//         </div>
//         <div className="md:hidden relative">
//           <button
//             onClick={toggleDropdown}
//             className="px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition"
//           >
//             選單
//           </button>
//           {isDropdownOpen && (
//             <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-lg shadow-xl z-20">
//               <button 
//                 onClick={goToHome} 
//                 className="block px-4 py-2 text-blue-500 hover:bg-blue-200 hover:text-white transition w-full text-left">
//                 首頁
//               </button>
//               {!isAuthenticated ? (
//                 <button 
//                   onClick={() => navigate('/login')} 
//                   className="block px-4 py-2 text-blue-500 hover:bg-blue-200 hover:text-white transition w-full text-left">
//                   登入
//                 </button>
//               ) : (
//                 <>
//                   <button 
//                     onClick={goToProfile}
//                     className="block px-4 py-2 text-blue-500 hover:bg-blue-200 hover:text-white transition w-full text-left"
//                     disabled={true}
//                   >
//                     會員資料
//                   </button>
//                   <button 
//                     onClick={goToAdmin}
//                     className="block px-4 py-2 text-blue-500 hover:bg-blue-200 hover:text-white transition w-full text-left"
//                     // disabled={true}
//                   >
//                     後台管理
//                   </button>
//                   <button 
//                     onClick={handleLogout} 
//                     className="block px-4 py-2 text-blue-500 hover:bg-blue-200 hover:text-white transition w-full text-left">
//                     登出
//                   </button>
//                 </>
//               )}
//             </div>
//           )}
//         </div>
//       </div>
//       {isMobile && (
//         <div className="fixed bottom-0 left-0 w-full bg-yellow-400 text-black text-center py-2 z-50 text-pretty">
//           <p>使用電腦版檢視，以獲得更佳瀏覽體驗</p>
//           <p>Please use larger monitor for better experience.</p>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Header;



// // 1.5 加入rwd設計: 按鈕下拉式選單
// // src/components/Common/Header.js
// import React, { useContext, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { AuthContext } from '../../context/AuthContext';

// const Header = () => {
//   const { isAuthenticated, logout } = useContext(AuthContext);
//   const navigate = useNavigate();
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

//   const handleLogout = () => {
//     logout();
//     navigate('/login');
//   };

//   const goToHome = () => {
//     navigate('/home');
//   };

//   const goToProfile = () => {
//     navigate('/profile');
//   };

//   const goToAdmin = () => {
//     navigate('/admin');
//   };

//   const toggleDropdown = () => {
//     setIsDropdownOpen(!isDropdownOpen);
//   };

//   return (
//     <div className="flex justify-between items-center p-4 bg-blue-500 text-white">
//       <h1 className="text-2xl">停車場佈告欄</h1>
//       <div className="hidden md:flex">
//         <button 
//           onClick={goToHome} 
//           className="mr-4 px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition">
//           首頁
//         </button>
//         {!isAuthenticated ? (
//           <button 
//             onClick={() => navigate('/login')} 
//             className="mr-4 px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition">
//             登入
//           </button>
//         ) : (
//           <>
//             <button 
//               onClick={goToProfile}
//               className="mr-4 px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition"
//               disabled={true}  
//             >
//               會員資料
//             </button>
//             <button 
//               onClick={goToAdmin}
//               className="mr-4 px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition"
//               disabled={true}  
//             >
//               後台管理
//             </button>
//             <button 
//               onClick={handleLogout} 
//               className="mr-4 px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition">
//               登出
//             </button>
//           </>
//         )}
//       </div>
//       <div className="md:hidden relative">
//         <button
//           onClick={toggleDropdown}
//           className="px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition"
//         >
//           選單
//         </button>
//         {isDropdownOpen && (
//           <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-lg shadow-xl z-20">
//             <button 
//               onClick={goToHome} 
//               className="block px-4 py-2 text-blue-500 hover:bg-blue-200 hover:text-white transition w-full text-left">
//               首頁
//             </button>
//             {!isAuthenticated ? (
//               <button 
//                 onClick={() => navigate('/login')} 
//                 className="block px-4 py-2 text-blue-500 hover:bg-blue-200 hover:text-white transition w-full text-left">
//                 登入
//               </button>
//             ) : (
//               <>
//                 <button 
//                   onClick={goToProfile}
//                   className="block px-4 py-2 text-blue-500 hover:bg-blue-200 hover:text-white transition w-full text-left"
//                   disabled={true}
//                 >
//                   會員資料
//                 </button>
//                 <button 
//                   onClick={goToAdmin}
//                   className="block px-4 py-2 text-blue-500 hover:bg-blue-200 hover:text-white transition w-full text-left"
//                   disabled={true}
//                 >
//                   後台管理
//                 </button>
//                 <button 
//                   onClick={handleLogout} 
//                   className="block px-4 py-2 text-blue-500 hover:bg-blue-200 hover:text-white transition w-full text-left">
//                   登出
//                 </button>
//               </>
//             )}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Header;


// // 1.4 加入rwd設計
// import React, { useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { AuthContext } from '../../context/AuthContext';

// const Header = () => {
//   const { isAuthenticated, logout } = useContext(AuthContext);
//   const navigate = useNavigate();

//   const handleLogout = () => {
//     logout();
//     navigate('/login');
//   };

//   // [首頁]按鈕
//   const goToHome = () => {
//     navigate('/home');
//   };

//   // [會員資料]按鈕，暫不開放
//   const goToProfile = () => {
//     navigate('/profile');
//   };

//   // [後台管理]按鈕，暫不開放
//   const goToAdmin = () => {
//     navigate('/admin');
//   };

//   return (
//     <div className="flex justify-between items-center p-4 bg-blue-500 text-white">
//       <h1 className="text-2xl">停車場佈告欄</h1>
//       <div>
//         <button 
//           onClick={goToHome} 
//           className="mr-4 px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition">
//           首頁
//         </button>
//         {!isAuthenticated ? (
//           <button 
//             onClick={() => navigate('/login')} 
//             className="mr-4 px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition">
//             登入
//           </button>
//         ) : (
//           <>
//             {/* 暫不開放，顏色由 bg-white 改為 bg-gray-700，移除 hover:bg-blue-200 */}
//             <button 
//               onClick={goToProfile}
//               className="mr-4 px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition"
//               disabled={true}  
//             >
//               會員資料
//             </button>
//             {/* 暫不開放，顏色由 bg-white 改為 bg-gray-700，移除 hover:bg-blue-200 */}
//             <button 
//               onClick={goToAdmin}
//               className="mr-4 px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition"
//               disabled={true}  
//             >
//               後台管理
//             </button>
//             <button 
//               onClick={handleLogout} 
//               className="mr-4 px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition">
//               登出
//             </button>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Header;


// 1.3 原版
// import React, { useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { AuthContext } from '../../context/AuthContext';

// const Header = () => {
//   const { isAuthenticated, logout } = useContext(AuthContext);
//   const navigate = useNavigate();

//   const handleLogout = () => {
//     logout();
//     navigate('/login');
//   };

//   const goToHome = () => {
//     navigate('/home');
//   };

//   const goToProfile = () => {
//     navigate('/profile');
//   };

//   const goToAdmin = () => {
//     navigate('/admin');
//   };

//   return (
//     <div className="flex justify-between items-center p-4 bg-blue-500 text-white">
//       <h1 className="text-2xl">停車場佈告欄</h1>
//       <div>
//         <button 
//           onClick={goToHome} 
//           className="mr-4 px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition">
//           首頁
//         </button>
//         {!isAuthenticated ? (
//           <button 
//             onClick={() => navigate('/login')} 
//             className="mr-4 px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition">
//             登入
//           </button>
//         ) : (
//           <>
//             <button 
//               onClick={goToProfile} 
//               className="mr-4 px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition">
//               會員資料
//             </button>
//             <button 
//               onClick={goToAdmin} 
//               className="mr-4 px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition">
//               後台管理
//             </button>
//             <button 
//               onClick={handleLogout} 
//               className="mr-4 px-4 py-2 bg-white text-blue-500 rounded-full border border-blue-500 hover:bg-blue-200 hover:text-white transition">
//               登出
//             </button>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Header;
