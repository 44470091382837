// src/pages/HomePage.js
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import Header from '../components/Common/Header';
import withAuth from '../components/Auth/withAuth';
import { AuthContext } from '../context/AuthContext';

import iconPhone from '../assets/icons/icon_phone.svg';
import iconPinAlt from '../assets/icons/icon_pin_alt.svg';

const HomePage = () => {
  const [buildings, setBuildings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const { isAuthenticated, isAuthenticating } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticating && isAuthenticated) {
      axios.get(`${config.apiBaseUrl}/buildings/`)
        .then(response => {
          setBuildings(response.data.buildings);
          setLoading(false);
        })
        .catch(error => {
          console.error('There was an error fetching the buildings!', error);
          setLoading(false);
        });
    }
  }, [isAuthenticated, isAuthenticating]);

  const handleNavigate = (id) => {
    navigate(`/building/${id}`);
  };

  const handleEditClick = () => {
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  if (loading || isAuthenticating) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Header />
      <div className="container mx-auto p-8">
        <h1 className="text-2xl font-bold mb-2">大樓列表</h1>
        <div className="w-full md:w-4/7 gap-4 p-4">
          {buildings.map(building => (
            <div key={building.id} className="p-4 border border-gray-300 rounded-lg">
              <div className="flex flex-col md:flex-row">
                <div className="w-7/8 md:w-1/4">
                  <img src={building.img_url} alt={building.name} className="w-full h-auto" />
                </div>
                <div className="w-full md:w-3/5 md:pl-4">
                  <h2 className="text-2xl font-bold items-center text-center md:text-justify md:mx-14 my-4">{building.name}</h2>
                  <p className="flex items-center md:mx-6 md:my-8">
                    <img src={iconPinAlt} alt="Address Icon" className="mr-2" />地址：{building.address}
                  </p>
                  <p className="flex items-center md:mx-6 my-4">
                    <img src={iconPhone} alt="Phone Icon" className="mr-2" />聯絡電話：{building.contact_phone}
                  </p>
                </div>
                <div className="w-full md:w-1/5 flex flex-row items-center justify-around">
                  <button
                    onClick={() => handleNavigate(building.id)}
                    className="bg-blue-500 text-white px-4 py-2 rounded md:mr-4 hover:bg-blue-400"
                  >
                    進入
                  </button>
                  <button
                    onClick={handleEditClick}
                    className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-400"
                  >
                    編輯
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      
      {showAlert && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg relative flex items-center justify-center" style={{ width: '180px', height: '80px' }}>
            <button
              onClick={handleCloseAlert}
              className="absolute top-0 right-0 mt-2 mr-2 text-xl text-gray-600 hover:text-gray-900"
            >
              &times;
            </button>
            <div className="text-center">
              <p className="text-lg font-bold">權限不足。</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withAuth(HomePage);


// // src/pages/HomePage.js
// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import config from '../config';
// import Header from '../components/Common/Header';
// import withAuth from '../components/Auth/withAuth';

// import iconPhone from '../assets/icons/icon_phone.svg';
// import iconPinAlt from '../assets/icons/icon_pin_alt.svg';

// const HomePage = () => {
//   const [buildings, setBuildings] = useState([]);
//   const [showAlert, setShowAlert] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     axios.get(`${config.apiBaseUrl}/buildings/`)
//       .then(response => {
//         setBuildings(response.data.buildings);
//       })
//       .catch(error => {
//         console.error('There was an error fetching the buildings!', error);
//       });
//   }, []);

//   const handleNavigate = (id) => {
//     navigate(`/building/${id}`);
//   };

//   const handleEditClick = () => {
//     setShowAlert(true);
//   };

//   const handleCloseAlert = () => {
//     setShowAlert(false);
//   };

//   return (
//     <div>
//       <Header />
//       <div className="container mx-auto p-8">
//         <h1 className="text-2xl font-bold mb-2">大樓列表</h1>
//         {/* <div className="grid grid-cols-1 gap-4 p-4"> */}
//         <div className="w-full md:w-4/7 gap-4 p-4">
//           {buildings.map(building => (
//             <div key={building.id} className="p-4 border border-gray-300 rounded-lg">
//               <div className="flex flex-col md:flex-row">
//                 <div className="w-7/8 md:w-1/4">
//                   <img src={building.img_url} alt={building.name} className="w-full h-auto" />
//                 </div>
//                 <div className="w-full md:w-3/5 md:pl-4">
//                   <h2 className="text-2xl font-bold items-center text-center md:text-justify md:mx-14 my-4">{building.name}</h2>
//                   <p className="flex items-center md:mx-6 md:my-8">
//                     <img src={iconPinAlt} alt="Address Icon" className="mr-2" />地址：{building.address}
//                   </p>
//                   <p className="flex items-center md:mx-6 my-4">
//                     <img src={iconPhone} alt="Phone Icon" className="mr-2" />聯絡電話：{building.contact_phone}
//                   </p>
//                 </div>
//                 <div className="w-full md:w-1/5 flex flex-row items-center justify-around">
//                   <button
//                     onClick={() => handleNavigate(building.id)}
//                     className="bg-blue-500 text-white px-4 py-2 rounded md:mr-4 hover:bg-blue-400"
//                   >
//                     進入
//                   </button>
//                   <button
//                     onClick={handleEditClick}
//                     className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-400"
//                   >
//                     編輯
//                   </button>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
      
//       {showAlert && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
//           <div className="bg-white p-6 rounded shadow-lg relative flex items-center justify-center" style={{ width: '180px', height: '80px' }}>
//             <button
//               onClick={handleCloseAlert}
//               className="absolute top-0 right-0 mt-2 mr-2 text-xl text-gray-600 hover:text-gray-900"
//             >
//               &times;
//             </button>
//             <div className="text-center">
//               <p className="text-lg font-bold">權限不足。</p>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default withAuth(HomePage);