// 1.7
// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import withAuth from './components/Auth/withAuth';

import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import BuildingDetailPage from './pages/BuildingDetailPage';
import AdminPage from './pages/AdminPage';

const ProtectedHomePage = withAuth(HomePage);
const ProtectedBuildingDetailPage = withAuth(BuildingDetailPage);
const ProtectedAdminPage = withAuth(AdminPage, ['user9', 'user10']); // 允許 user9 和 user10 訪問

const App = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/home" element={<ProtectedHomePage />} />
      <Route path="/building/:id" element={<ProtectedBuildingDetailPage />} />
      <Route path="/admin" element={<ProtectedAdminPage />} />
      <Route path="/" element={<Navigate to="/home" />} />
    </Routes>
  );
};

const AppWithAuth = () => (
  <AuthProvider>
    <Router>
      <App />
    </Router>
  </AuthProvider>
);

export default AppWithAuth;



// // 1.7以前適用
// import React from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import { AuthProvider } from './context/AuthContext';
// import withAuth from './components/Auth/withAuth';

// import LoginPage from './pages/LoginPage';
// import HomePage from './pages/HomePage';
// import BuildingDetailPage from './pages/BuildingDetailPage';
// import AdminPage from './pages/AdminPage';

// const ProtectedHomePage = withAuth(HomePage);
// const ProtectedBuildingDetailPage = withAuth(BuildingDetailPage);
// const ProtectedAdminPage = withAuth(AdminPage);

// const App = () => {
//   return (
//     <Routes>
//       <Route path="/login" element={<LoginPage />} />
//       <Route path="/home" element={<ProtectedHomePage />} />
//       <Route path="/building/:id" element={<ProtectedBuildingDetailPage />} />
//       {/* <Route path="/admin/*" element={<ProtectedAdminPage />} /> */}
//       <Route path="/admin" element={<ProtectedAdminPage />} />
//       <Route path="/" element={<Navigate to="/home" />} />
//     </Routes>
//   );
// };

// const AppWithAuth = () => (
//   <AuthProvider>
//     <Router>
//       <App />
//     </Router>
//   </AuthProvider>
// );

// export default AppWithAuth;


// import React, { useEffect, useContext } from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
// import LoginPage from './pages/LoginPage';
// import HomePage from './pages/HomePage';
// import BuildingDetailPage from './pages/BuildingDetailPage';
// import { AuthContext, AuthProvider, PrivateRoute } from './context/AuthContext';

// const App = () => {
//   const { isAuthenticated } = useContext(AuthContext);
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (!isAuthenticated) {
//       navigate('/login');
//       console.log('test You are not authenticated. Redirecting to login page...');


//     } else if (window.location.pathname === '/') {
//       navigate('/home');
//     }
//   }, [isAuthenticated, navigate]);

//   return (
//     <Routes>
//       <Route path="/login" element={<LoginPage />} />
//       <Route path="/home" element={<PrivateRoute><HomePage /></PrivateRoute>} />
//       <Route path="/building/:id" element={<PrivateRoute><BuildingDetailPage /></PrivateRoute>} />
//       <Route path="/" element={<Navigate to={isAuthenticated ? "/home" : "/login"} />} />
//     </Routes>
//   );
// };

// const AppWithAuth = () => (
//   <AuthProvider>
//     <Router>
//       <App />
//     </Router>
//   </AuthProvider>
// );

// export default AppWithAuth;
