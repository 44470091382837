// // 1.4 rwd設計
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ParkingLot from '../components/Building/ParkingLot';
import Header from '../components/Common/Header';
import config from '../config';
import { useParams } from 'react-router-dom';
import withAuth from '../components/Auth/withAuth';

import iconPhone from '../assets/icons/icon_phone.svg';
import iconPinAlt from '../assets/icons/icon_pin_alt.svg';

const statusColors = {
  buyable:          { color: '#E6C35C', label: '可出售' },
  rentable:         { color: '#00aac8', label: '可出租' },
  buyable_rentable: { color: '#E6C3C3', label: '可租/可售' },
  // unusable:         { color: '#ADADAD', label: '無法使用' }
  unusable:         { color: '#cccccc', label: '無法使用' }
};

const BuildingDetailPage = () => {
  const { id } = useParams();
  const [building, setBuilding] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);

  useEffect(() => {
    axios.get(`${config.apiBaseUrl}/building/${id}/`)
      .then(response => {
        setBuilding(response.data);
        if (response.data.floors.length > 0) {
          setSelectedFloor(response.data.floors[0].id);
        }
      })
      .catch(error => {
        console.error('There was an error fetching the building details!', error);
      });
  }, [id]);

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-1 flex-col md:flex-row">
        <div className="w-full md:max-w-72 md:min-w-72 p-5 border-r border-gray-300 overflow-y-auto bg-green-50">
          {building && (
            <>
              <div className="w-full border-b border-gray-300 pb-5 mb-5 bg-white rounded-lg p-4">
                <img src={building.img_url} alt={building.name} className="w-11/12 md:max-w-11/12 mb-5 mx-auto rounded" />
                <h2 className="text-lg text-center md:flex font-bold items-center">
                  {building.name}
                </h2>
                <p className="flex items-center my-2 text-pretty">
                  <img src={iconPinAlt} alt="Address Icon" className="mr-2" /> {building.address}
                </p>
                <p className="flex items-center text-pretty">
                  <img src={iconPhone} alt="Phone Icon" className="mr-2" /> {building.contact_phone}
                </p>
              </div>
              <div className='grid justify-items-center'>
                {building.floors.map(floor => (
                  <button
                    key={floor.id}
                    onClick={() => setSelectedFloor(floor.id)}
                    className={`block w-3/5 md:w-4/5 p-2 my-1 items-center rounded ${selectedFloor === floor.id ? 'bg-blue-500 text-white hover:bg-blue-600' : 'bg-gray-200 hover:bg-gray-400'}`}
                  >
                    {floor.name}
                  </button>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="flex-1 flex flex-col bg-blue-50">
          <div className="p-5 border-b border-gray-300 flex justify-between md:flex-row flex-col">
            <h2 className="text-xl font-bold">{building?.name} - {building?.floors.find(floor => floor.id === selectedFloor)?.name}</h2>
            <div className="flex items-center grid grid-flow-col justify-stretch">
              {Object.entries(statusColors).map(([key, { color, label }]) => (
                <div key={key} className="flex items-center mr-2 grid grid-flow-col justify-stretch">
                  <span className="inline-block w-5 h-5 mr-2" style={{ backgroundColor: color }}></span>
                  <span className='text-nowrap'>{label}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="flex-1 overflow-hidden">
            {building && selectedFloor && <ParkingLot buildingId={id} floorId={selectedFloor} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAuth(BuildingDetailPage);


// // 1.3 原版
// // src/pages/BuildingDetailPage.js
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import ParkingLot from '../components/Building/ParkingLot';
// import Header from '../components/Common/Header';
// import config from '../config';
// import { useParams } from 'react-router-dom';
// import withAuth from '../components/Auth/withAuth';

// import iconPhone from '../assets/icons/icon_phone.svg';
// import iconPinAlt from '../assets/icons/icon_pin_alt.svg';

// const statusColors = {
//   buyable: { color: '#E6C35C', label: '可出售' },
//   rentable: { color: '#00aac8', label: '可出租' },
//   buyable_rentable: { color: '#E6C3C3', label: '可租/可售' },
//   unusable: { color: '#ADADAD', label: '無法使用' }
// };

// const BuildingDetailPage = () => {
//   const { id } = useParams();
//   const [building, setBuilding] = useState(null);
//   const [selectedFloor, setSelectedFloor] = useState(null);

//   useEffect(() => {
//     axios.get(`${config.apiBaseUrl}/building/${id}/`)
//       .then(response => {
//         setBuilding(response.data);
//         if (response.data.floors.length > 0) {
//           setSelectedFloor(response.data.floors[0].id);
//         }
//       })
//       .catch(error => {
//         console.error('There was an error fetching the building details!', error);
//       });
//   }, [id]);

//   return (
//     <div className="flex flex-col h-screen">
//       <Header />
//       <div className="flex flex-1">
//         <div className="w-72 p-5 border-r border-gray-300 overflow-y-auto bg-green-50 min-w-[287px]">
//           {building && (
//             <>
//               <div className="border-b border-gray-300 pb-5 mb-5 bg-white rounded-lg p-4">
//                 <img src={building.img_url} alt={building.name} className="w-11/12 mb-5 mx-auto rounded" />
//                 <h2 className="text-lg font-bold flex items-center">
//                   {building.name}
//                 </h2>
//                 <p className="flex items-center">
//                   <img src={iconPinAlt} alt="Address Icon" className="mr-2" /> {building.address}
//                 </p>
//                 <p className="flex items-center">
//                   <img src={iconPhone} alt="Phone Icon" className="mr-2" /> {building.contact_phone}
//                 </p>
//               </div>
//               <div>
//                 {building.floors.map(floor => (
//                   <button
//                     key={floor.id}
//                     onClick={() => setSelectedFloor(floor.id)}
//                     className={`block w-48 p-2 my-1 items-center rounded ${selectedFloor === floor.id ? 'bg-blue-500 text-white hover:bg-blue-600' : 'bg-gray-200 hover:bg-gray-400'}`}
//                   >
//                     {floor.name}
//                   </button>
//                 ))}
//               </div>
//             </>
//           )}
//         </div>
//         <div className="flex-1 flex flex-col bg-blue-50">
//           <div className="p-5 border-b border-gray-300 flex justify-between">
//             <h2 className="text-xl font-bold">{building?.name} - {building?.floors.find(floor => floor.id === selectedFloor)?.name}</h2>
//             <div className="flex items-center">
//               {Object.entries(statusColors).map(([key, { color, label }]) => (
//                 <div key={key} className="flex items-center mr-5">
//                   <span className="inline-block w-5 h-5 mr-2" style={{ backgroundColor: color }}></span>
//                   <span>{label}</span>
//                 </div>
//               ))}
//             </div>
//           </div>
//           <div className="flex-1 overflow-hidden">
//             {selectedFloor && <ParkingLot buildingId={id} floorId={selectedFloor} />}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default withAuth(BuildingDetailPage);
