// 1.7 版本
// src/pages/LoginPage.js
import React, { useState, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import iconUser from '../assets/icons/icon_user.svg';
import iconPassword from '../assets/icons/icon_password.svg';
import iconEyeOpen from '../assets/icons/icon_eye_open.svg';
import iconEyeClose from '../assets/icons/icon_eye_close.svg';
import userCredentials from '../config/userCredentials'; // 导入用户凭证

const LoginPage = () => {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = () => {
    const user = userCredentials.find(
      (user) => user.username === username && user.password === password
    );

    if (user) {
      login(username); // 调用 login 函数并传递用户名
      navigate('/home');
    } else {
      setError('帳號或密碼錯誤');
    }
  };

  return (
    <div>
      <div className="flex justify-center items-center min-h-screen" style={{ backgroundColor: '#F2F8F8' }}>
        <div className="w-96 h-96 bg-white shadow-lg flex flex-col items-center rounded" style={{ width: '463px', height: '470px' }}>
          <h1 className="text-4xl font-bold mt-14" style={{ fontFamily: 'Noto Sans TC', fontSize: '40px' }}>停車場佈告欄</h1>
          <div className="mt-16 w-80 relative">
            <img src={iconUser} alt="user icon" className="absolute left-3 top-1/2 transform -translate-y-1/2" />
            <input
              type="text"
              placeholder="請輸入帳號"
              className="pl-10 w-full h-12 border border-gray-300 rounded"
              style={{ fontFamily: 'Noto Sans TC', fontSize: '16px' }}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          {error && <div className="w-80 mt-1 text-red-500" style={{ fontFamily: 'Noto Sans TC', fontSize: '12px' }}>{error}</div>}
          <div className="mt-2 w-80 relative">
            <img src={iconPassword} alt="password icon" className="absolute left-3 top-1/2 transform -translate-y-1/2" />
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder="請輸入密碼"
              className="pl-10 w-full h-12 border border-gray-300 rounded"
              style={{ fontFamily: 'Noto Sans TC', fontSize: '16px' }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-3 top-1/2 transform -translate-y-1/2"
            >
              <img src={showPassword ? iconEyeOpen : iconEyeClose} alt="toggle password visibility" />
            </button>
          </div>
          <button
            className="my-10 w-80 h-14 bg-blue-500 text-white rounded"
            style={{ fontFamily: 'Noto Sans TC', fontSize: '20px' }}
            onClick={handleLogin}
          >
            登入
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
