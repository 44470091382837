// src/components/Admin/ParkingSpaceForm.js
import React, { useState } from 'react';
import axios from 'axios';
import config from '../../config';

const ParkingSpaceForm = ({ buildingId, floorId, spaceData, onSubmit, onCancel, isEdit }) => {
  const [formData, setFormData] = useState({
    number: spaceData.number || '',
    status: spaceData.status || '',
    contact_name: spaceData.contact_name || '',
    contact_phone: spaceData.contact_phone || '',
    monthly_rent: spaceData.monthly_rent !== null ? spaceData.monthly_rent : '',
    yearly_rent: spaceData.yearly_rent !== null ? spaceData.yearly_rent : '',
    price: spaceData.price !== null ? spaceData.price : '',
    note: spaceData.note || '',
    date_added: spaceData.date_added || '',
    vehicle_type: spaceData.vehicle_type || ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // 檢查所有必填欄位是否填寫
    if (!formData.number || !formData.status || !formData.contact_name || !formData.contact_phone) {
      alert('請填寫所有必填欄位');
      return;
    }

    // 檢查 status 對應的必填欄位
    if (formData.status === 'buyable' && !formData.price) {
      alert("可租售狀態為'buyable'時，應填寫'price'。");
      return;
    }
    if (formData.status === 'rentable' && !formData.monthly_rent && !formData.yearly_rent) {
      alert("可租售狀態為'rentable'時，應填寫'monthly_rent'或'yearly_rent'。");
      return;
    }
    if (formData.status === 'buyable_rentable' && (!formData.price || (!formData.monthly_rent && !formData.yearly_rent))) {
      alert("可租售狀態為'buyable_rentable'時，應填寫'price'、'monthly_rent'或'yearly_rent'。");
      return;
    }

    // 構建請求數據，只包括非空字段
  const data = {
    secret_key: config.secret_key,
    building_id: buildingId,
    floor_id: floorId,
    number: formData.number,
    status: formData.status,
    contact_name: formData.contact_name,
    contact_phone: formData.contact_phone,
    monthly_rent: formData.monthly_rent ? parseInt(formData.monthly_rent) : undefined,
    yearly_rent: formData.yearly_rent ? parseInt(formData.yearly_rent) : undefined,
    price: formData.price ? parseInt(formData.price) : undefined,
    note: formData.note,
    date_added: formData.date_added,
    vehicle_type: formData.vehicle_type
  };

    try {
      // const response = await axios.post(`${config.apiBaseUrl}/parking_space`, data);
      const response = isEdit
        ? await axios.put(`${config.apiBaseUrl}/parking_space`, data)
        : await axios.post(`${config.apiBaseUrl}/parking_space`, data);

      onSubmit();
      console.log(response.data.message);
      alert(response.data.message);
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data) {
        const errorDetails = JSON.stringify(error.response.data.detail, null, 2); // 美化JSON字符串
        alert(`Error: ${errorDetails}`); // 顯示具體的錯誤信息
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block mb-1">車位編號</label>
        <input
          type="text"
          name="number"
          value={formData.number}
          onChange={handleChange}
          className="w-full p-2 border rounded"
          required
        />
      </div>
      <div>
        <label className="block mb-1">填表日期</label>
        <input
          type="date"
          name="date_added"
          value={formData.date_added}
          onChange={handleChange}
          className="w-full p-2 border rounded"
          required
        />
      </div>
      <div>
        <label className="block mb-1">車輛類型</label>
        <select
          name="vehicle_type"
          value={formData.vehicle_type}
          onChange={handleChange}
          className="w-full p-2 border rounded"
        >
          <option value="">選擇車輛類型</option>
          <option value="car">汽車</option>
          <option value="motorcycle">機車</option>
          <option value="car_motorcycle">汽機車</option>
        </select>
      </div>
      <div>
        <label className="block mb-1">可租售狀態</label>
        <select
          name="status"
          value={formData.status}
          onChange={handleChange}
          className="w-full p-2 border rounded"
          required
        >
          <option value="">選擇狀態</option>
          <option value="buyable">可出售</option>
          <option value="rentable">可出租</option>
          <option value="buyable_rentable">可租可售</option>
          <option value="unusable">不可用</option>
        </select>
      </div>
      <div>
        <label className="block mb-1">聯絡人姓名</label>
        <input
          type="text"
          name="contact_name"
          value={formData.contact_name}
          onChange={handleChange}
          className="w-full p-2 border rounded"
          required
        />
      </div>
      <div>
        <label className="block mb-1">聯絡人電話</label>
        <input
          type="text"
          name="contact_phone"
          value={formData.contact_phone}
          onChange={handleChange}
          className="w-full p-2 border rounded"
          required
        />
      </div>
      <div>
        <label className="block mb-1">月租</label>
        <input
          type="number"
          name="monthly_rent"
          value={formData.monthly_rent}
          onChange={handleChange}
          className="w-full p-2 border rounded"
        />
      </div>
      <div>
        <label className="block mb-1">年租</label>
        <input
          type="number"
          name="yearly_rent"
          value={formData.yearly_rent}
          onChange={handleChange}
          className="w-full p-2 border rounded"
        />
      </div>
      <div>
        <label className="block mb-1">售價</label>
        <input
          type="number"
          name="price"
          value={formData.price}
          onChange={handleChange}
          className="w-full p-2 border rounded"
        />
      </div>
      <div>
        <label className="block mb-1">備註</label>
        <textarea
          name="note"
          value={formData.note}
          onChange={handleChange}
          className="w-full p-2 border rounded"
        />
      </div>
      <div className="flex space-x-4">
        <button type="submit" className="p-2 bg-blue-500 text-white rounded">提交</button>
        <button type="button" onClick={onCancel} className="p-2 bg-gray-500 text-white rounded">取消</button>
      </div>
    </form>
  );
};

export default ParkingSpaceForm;
