// src/config/userCredentials.js
const userCredentials = [
    // Add more user credentials here, username should list with 'user'+number, password should be 8 bit random string, create 10 accounts.
    { username: 'user', password: '86014304' },
    { username: 'user2', password: '96713462' },
    { username: 'user3', password: '46258941' },
    { username: 'user4', password: '73658958' },
    { username: 'user5', password: '64251344' },
    { username: 'user6', password: '57489655' },
    { username: 'user7', password: '78446391' },
    { username: 'user8', password: '24625819' },
    { username: 'user9', password: '34257618' },
    { username: 'user10', password: '64251879' }
  ];
  
  export default userCredentials;
  