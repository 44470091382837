// src/components/Admin/BuildingDetails.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config';

const BuildingDetails = ({ setBuildingId, setFloorId }) => {
    const [buildings, setBuildings] = useState([]);
    const [floors, setFloors] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState('');
    const [selectedFloor, setSelectedFloor] = useState('');
    const [buildingInfo, setBuildingInfo] = useState({ img_url: '', address: '', contact_phone: '' });

    useEffect(() => {
        axios.get(`${config.apiBaseUrl}/buildings/`)
            .then(response => setBuildings(response.data.buildings))
            .catch(error => console.error(error));
    }, []);

    const handleBuildingChange = (event) => {
        const buildingId = event.target.value;
        setSelectedBuilding(buildingId);
        setBuildingId(buildingId);
        axios.get(`${config.apiBaseUrl}/building/${buildingId}/`)
            .then(response => {
                setFloors(response.data.floors);
                setBuildingInfo({
                    name: response.data.name,
                    img_url: response.data.img_url,
                    address: response.data.address,
                    contact_phone: response.data.contact_phone
                });
            })
            .catch(error => console.error(error));
    };

    const handleFloorChange = (event) => {
        const floorId = event.target.value;
        setSelectedFloor(floorId);
        setFloorId(floorId);
    };

    return (
        <div className="w-full md:w-1/4 p-4 md:min-w-[200px]">
            <div className="mb-4 p-4 border rounded bg-gray-100">
                <div className="h-200 w-full mb-4 bg-gray-300">
                    {buildingInfo.img_url && <img src={buildingInfo.img_url} alt="Building" className="w-full md:w-min-[200px] md:w-max-[200px] h-auto object-cover" />}
                </div>
                <div className="mb-2">
                    <strong>名稱：</strong> {buildingInfo.name || '未選擇'}
                </div>
                <div className="mb-2">
                    <strong>地址：</strong> {buildingInfo.address || '未選擇'}
                </div>
                <div className="mb-2">
                    <strong>電話：</strong> {buildingInfo.contact_phone || '未選擇'}
                </div>
            </div>
            <div className="mb-4">
                <label htmlFor="building-select" className="block mb-2">大樓：</label>
                <select id="building-select" value={selectedBuilding} onChange={handleBuildingChange} className="w-full p-2 border rounded">
                    <option value="">選擇大樓</option>
                    {buildings.map(building => (
                        <option key={building.id} value={building.id}>{building.name}</option>
                    ))}
                </select>
            </div>
            <div className="mb-4">
                <label htmlFor="floor-select" className="block mb-2">樓層：</label>
                <select id="floor-select" value={selectedFloor} onChange={handleFloorChange} className="w-full p-2 border rounded">
                    <option value="">選擇樓層</option>
                    {floors.map(floor => (
                        <option key={floor.id} value={floor.id}>{floor.name}</option>
                    ))}
                </select>
            </div>
            <button className="w-full p-2 bg-blue-500 text-white rounded" onClick={() => { /* 搜尋邏輯 */ }}>搜尋</button>
        </div>
    );
};

export default BuildingDetails;
