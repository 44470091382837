// src/components/Admin/ParkingSpaceTable.js
import React, { useEffect, useState, useCallback } from 'react';
import { useTable, usePagination } from 'react-table';
import axios from 'axios';
import config from '../../config';
import ParkingSpaceForm from './ParkingSpaceForm';

const ParkingSpaceTable = ({ buildingId, floorId }) => {
    const [parkingSpaces, setParkingSpaces] = useState([]);
    const [editSpace, setEditSpace] = useState(null);
    const [operationLogs, setOperationLogs] = useState([]);

    useEffect(() => {
        if (buildingId && floorId) {
            axios.get(`${config.apiBaseUrl}/building/${buildingId}/parking_floor/${floorId}`)
                .then(response => setParkingSpaces(response.data.floor_data || []))
                .catch(error => console.error(error));
        }
    }, [buildingId, floorId]);

    const handleDelete = useCallback(async (space) => {
        if (window.confirm(`是否刪除 ${space.number} 號車位？`)) {
            try {
                const response = await axios.delete(`${config.apiBaseUrl}/parking_space`, {
                    params: {
                        secret_key: config.secret_key,
                        building_id: buildingId,
                        floor_id: floorId,
                        number: space.number
                    }
                });
                setOperationLogs(prevLogs => [...prevLogs, response.data.message]);
                setParkingSpaces(prevSpaces => prevSpaces.filter(s => s.id !== space.id));
            } catch (error) {
                console.error(error);
                if (error.response && error.response.data && error.response.data.detail) {
                    alert(`刪除失敗：${error.response.data.detail}`);
                } else {
                    alert('刪除失敗，請稍後再試。');
                }
            }
        }
    }, [buildingId, floorId]);

    const columns = React.useMemo(() => [
        { Header: '車位編號', accessor: 'number' },
        { Header: '填表日期', accessor: 'date_added' },
        { Header: '車輛類型', accessor: 'vehicle_type' },
        { Header: '可租售狀態', accessor: 'status' },
        { Header: '售價', accessor: 'price' },
        { Header: '月租', accessor: 'monthly_rent' },
        { Header: '年租', accessor: 'yearly_rent' },
        { Header: '聯絡人姓名', accessor: 'contact_name' },
        { Header: '聯絡人電話', accessor: 'contact_phone' },
        { Header: '備註', accessor: 'note' },
        { Header: '操作', Cell: ({ row }) => (
            <div className="flex space-x-2">
                <button 
                    onClick={() => handleEdit(row.original)}
                    className="px-2 py-1 bg-yellow-500 text-white rounded hover:bg-yellow-600"
                >
                    編輯
                </button>
                <button 
                    onClick={() => handleDelete(row.original)}
                    className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                >
                    刪除
                </button>
            </div>
        ) },
    ], [handleDelete]);

    const handleEdit = (space) => {
        setEditSpace(space);
    };

    const handleFormSubmit = () => {
        setEditSpace(null);
        axios.get(`${config.apiBaseUrl}/building/${buildingId}/parking_floor/${floorId}`)
            .then(response => setParkingSpaces(response.data.floor_data || []))
            .catch(error => console.error(error));
    };

    const handleFormCancel = () => {
        setEditSpace(null);
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state: { pageIndex },
        gotoPage,
    } = useTable(
        { columns, data: parkingSpaces, initialState: { pageIndex: 0 } },
        usePagination
    );

    return (
        <div className="w-3/4 p-4">
            <button className="mb-4 p-2 bg-green-500 text-white rounded" onClick={() => setEditSpace({})}>新增</button>
            {editSpace && (
                <ParkingSpaceForm
                    buildingId={buildingId}
                    floorId={floorId}
                    spaceData={editSpace}
                    onSubmit={handleFormSubmit}
                    onCancel={handleFormCancel}
                    isEdit={editSpace.number ? true : false}
                />
            )}
            <table {...getTableProps()} className="w-full border-collapse">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th key={column.id} {...column.getHeaderProps()} className="border p-2">{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <tr key={row.id} {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td key={cell.id} {...cell.getCellProps()} className="border p-2">{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="mt-4 flex space-x-2">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className="px-2 py-1 bg-gray-500 text-white rounded hover:bg-gray-600">第一頁</button>
                <button onClick={() => previousPage()} disabled={!canPreviousPage} className="px-2 py-1 bg-gray-500 text-white rounded hover:bg-gray-600">上一頁</button>
                <button onClick={() => gotoPage(Math.max(pageIndex - 5, 0))} disabled={!canPreviousPage} className="px-2 py-1 bg-gray-500 text-white rounded hover:bg-gray-600">向前5頁</button>
                <span> 第{pageIndex + 1}頁，共{pageOptions.length}頁 </span>
                <button onClick={() => gotoPage(Math.min(pageIndex + 5, pageOptions.length - 1))} disabled={!canNextPage} className="px-2 py-1 bg-gray-500 text-white rounded hover:bg-gray-600">向後5頁</button>
                <button onClick={() => nextPage()} disabled={!canNextPage} className="px-2 py-1 bg-gray-500 text-white rounded hover:bg-gray-600">下一頁</button>
                <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage} className="px-2 py-1 bg-gray-500 text-white rounded hover:bg-gray-600">最後一頁</button>
            </div>
            <div className="mt-4">
                {operationLogs.map((log, index) => (
                    <div key={index} className="p-2 bg-gray-100 rounded mb-2">{log}</div>
                ))}
            </div>
        </div>
    );
};

export default ParkingSpaceTable;




// import React, { useEffect, useState, useCallback } from 'react';
// import { useTable, usePagination } from 'react-table';
// import axios from 'axios';
// import config from '../../config';
// import ParkingSpaceForm from './ParkingSpaceForm';

// const ParkingSpaceTable = ({ buildingId, floorId }) => {
//     const [parkingSpaces, setParkingSpaces] = useState([]);
//     const [editSpace, setEditSpace] = useState(null);
//     const [operationLogs, setOperationLogs] = useState([]);

//     useEffect(() => {
//         if (buildingId && floorId) {
//             axios.get(`${config.apiBaseUrl}/building/${buildingId}/parking_floor/${floorId}`)
//                 .then(response => setParkingSpaces(response.data.floor_data || []))
//                 .catch(error => console.error(error));
//         }
//     }, [buildingId, floorId]);

//     const handleDelete = useCallback((space) => {
//         if (window.confirm(`是否刪除 ${space.number} 號車位？`)) {
//             axios.delete(`${config.apiBaseUrl}/parking_space`, {
//                 params: {
//                     secret_key: config.secret_key, // 從 config.js 引入 secret_key
//                     building_id: buildingId,
//                     floor_id: floorId,
//                     number: space.number
//                 }
//             })
//             .then(response => {
//                 setOperationLogs(prevLogs => [...prevLogs, response.data.message]);
//                 setParkingSpaces(prevSpaces => prevSpaces.filter(s => s.id !== space.id));
//             })
//             .catch(error => console.error(error));
//         }
//     }, [buildingId, floorId]);

//     const columns = React.useMemo(() => [
//         { Header: '車位編號', accessor: 'number' },
//         { Header: '填表日期', accessor: 'date_added' },
//         { Header: '車輛類型', accessor: 'vehicle_type' },
//         { Header: '可租售狀態', accessor: 'status' },
//         { Header: '售價', accessor: 'price' },
//         { Header: '月租', accessor: 'monthly_rent' },
//         { Header: '年租', accessor: 'yearly_rent' },
//         { Header: '聯絡人姓名', accessor: 'contact_name' },
//         { Header: '聯絡人電話', accessor: 'contact_phone' },
//         { Header: '備註', accessor: 'note' },
//         { Header: '操作', Cell: ({ row }) => (
//             <div className="flex space-x-2">
//                 <button 
//                     onClick={() => handleEdit(row.original)}
//                     className="px-2 py-1 bg-yellow-500 text-white rounded hover:bg-yellow-600"
//                 >
//                     編輯
//                 </button>
//                 <button 
//                     onClick={() => handleDelete(row.original)}
//                     className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
//                 >
//                     刪除
//                 </button>
//             </div>
//         ) },
//     ], [handleDelete]);

//     const handleEdit = (space) => {
//         setEditSpace(space);
//     };

//     const handleFormSubmit = () => {
//         setEditSpace(null);
//         axios.get(`${config.apiBaseUrl}/building/${buildingId}/parking_floor/${floorId}`)
//             .then(response => setParkingSpaces(response.data.floor_data || []))
//             .catch(error => console.error(error));
//     };

//     const handleFormCancel = () => {
//         setEditSpace(null);
//     };

//     const {
//         getTableProps,
//         getTableBodyProps,
//         headerGroups,
//         prepareRow,
//         page,
//         nextPage,
//         previousPage,
//         canNextPage,
//         canPreviousPage,
//         pageOptions,
//         state: { pageIndex },
//     } = useTable(
//         { columns, data: parkingSpaces, initialState: { pageIndex: 0 } },
//         usePagination
//     );

//     return (
//         <div className="w-3/4 p-4">
//             <button className="mb-4 p-2 bg-green-500 text-white rounded" onClick={() => setEditSpace({})}>新增</button>
//             {editSpace && (
//                 <ParkingSpaceForm
//                     buildingId={buildingId}
//                     floorId={floorId}
//                     spaceData={editSpace}
//                     onSubmit={handleFormSubmit}
//                     onCancel={handleFormCancel}
//                 />
//             )}
//             <table {...getTableProps()} className="w-full border-collapse">
//                 <thead>
//                     {headerGroups.map(headerGroup => (
//                         <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
//                             {headerGroup.headers.map(column => (
//                                 <th key={column.id} {...column.getHeaderProps()} className="border p-2">{column.render('Header')}</th>
//                             ))}
//                         </tr>
//                     ))}
//                 </thead>
//                 <tbody {...getTableBodyProps()}>
//                     {page.map(row => {
//                         prepareRow(row);
//                         return (
//                             <tr key={row.id} {...row.getRowProps()}>
//                                 {row.cells.map(cell => (
//                                     <td key={cell.id} {...cell.getCellProps()} className="border p-2">{cell.render('Cell')}</td>
//                                 ))}
//                             </tr>
//                         );
//                     })}
//                 </tbody>
//             </table>
//             <div className="mt-4">
//                 <button onClick={() => previousPage()} disabled={!canPreviousPage} className="px-2 py-1 bg-gray-500 text-white rounded hover:bg-gray-600">Previous</button>
//                 <span> Page {pageIndex + 1} of {pageOptions.length} </span>
//                 <button onClick={() => nextPage()} disabled={!canNextPage} className="px-2 py-1 bg-gray-500 text-white rounded hover:bg-gray-600">Next</button>
//             </div>
//             <div className="mt-4">
//                 {operationLogs.map((log, index) => (
//                     <div key={index} className="p-2 bg-gray-100 rounded mb-2">{log}</div>
//                 ))}
//             </div>
//         </div>
//     );
// };

// export default ParkingSpaceTable;
