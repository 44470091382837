// 對應版本: 1.7 修正版
// src/components/Auth/withAuth.js
import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const withAuth = (WrappedComponent, allowedUsers = []) => {
  return (props) => {
    const { isAuthenticated, isAuthenticating, currentUser } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
      if (isAuthenticating) {
        return;
      }

      if (!isAuthenticated) {
        console.log('You are not authenticated. Redirecting to login page...');
        navigate('/login');
      } else if (allowedUsers.length > 0 && !allowedUsers.includes(currentUser.username)) {
        console.log('You do not have access to this page. Redirecting to home page...');
        navigate('/home');
      }
    // }, [isAuthenticated, isAuthenticating, navigate, currentUser, allowedUsers]);
    }, [isAuthenticated, isAuthenticating, navigate, currentUser]);

    if (isAuthenticating) {
      return <div>Loading...</div>;
    }

    return isAuthenticated ? <WrappedComponent {...props} /> : null;
  };
};

export default withAuth;
